/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { cloneElement } from 'react';
import { LayoutProvider } from 'layouts/LayoutProvider';
import { DefaultLayout } from 'layouts/DefaultLayout';
import type { GatsbyBrowser } from 'gatsby';
import './src/styles/global.css';
import { Helmet } from 'react-helmet';
import Headeroffer from 'components/Headeroffer/Headeroffer';
export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
    return (
        <>
        <Helmet>          
           <script type="text/javascript">
            {`
               (function(c,l,a,r,i,t,y){
               c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
               t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
               y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
               })(window, document, "clarity", "script", "lneuxb9og1");
            `}
            </script>
            
            <script type="text/javascript">
                    {`
   var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
   (function(){
   var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
   s1.async=true;
   s1.src='https://embed.tawk.to/655b922dd600b968d315300a/1hfmr614i';
   s1.charset='UTF-8';
   s1.setAttribute('crossorigin','*');
   s0.parentNode.insertBefore(s1,s0);
   })(); 
   `}
                </script>

                <script type="application/ld+json">
                    {`
                    {
  "@context": "https://schema.org",
  "@type": "Corporation",
  "name": "Esspa",
  "alternateName": "Esspfa - B2b Digital Business Technology",
  "url": "https://esspfa.com/",
  "logo": "https://esspfa.com/static/a854db17f209a0d34db4e9f05d0092bf/590c6/logo.webp",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "7255676005",
    "contactType": "customer service",
    "contactOption": "TollFree",
    "areaServed": ["US","GB","CA"],
    "availableLanguage": "en"
  },{
    "@type": "ContactPoint",
    "telephone": "7255676005",
    "contactType": "technical support",
    "contactOption": "TollFree",
    "areaServed": ["US","GB","CA"],
    "availableLanguage": "en"
  }],
  "sameAs": [
    "https://www.facebook.com/people/Esspfa/100092539865685/",
    "https://www.linkedin.com/company/78370739",
    "https://www.instagram.com/esspfa/"
  ]
}
`}
                </script>
                {/* <script
                    type="text/javascript"
                    src="https://widget.clutch.co/static/js/widget.js"
                ></script> */}
            </Helmet>
            {/* <Headeroffer></Headeroffer> */}
            {element}
        </>
    );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
    if ((element.type as any).Layout === false) {
        return cloneElement(element, props);
    }
    const Layout = (element.type as any).Layout ?? DefaultLayout;
    return <Layout {...props}>{element}</Layout>;
};
