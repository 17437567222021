exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-hiring-category-tsx": () => import("./../../../src/templates/Hiring-category.tsx" /* webpackChunkName: "component---src-templates-hiring-category-tsx" */),
  "component---src-templates-hiring-sub-category-tsx": () => import("./../../../src/templates/Hiring-sub-category.tsx" /* webpackChunkName: "component---src-templates-hiring-sub-category-tsx" */),
  "component---src-templates-individual-blog-tsx": () => import("./../../../src/templates/individual-blog.tsx" /* webpackChunkName: "component---src-templates-individual-blog-tsx" */),
  "component---src-templates-individual-portfolio-tsx": () => import("./../../../src/templates/Individual-portfolio.tsx" /* webpackChunkName: "component---src-templates-individual-portfolio-tsx" */),
  "component---src-templates-individual-service-tsx": () => import("./../../../src/templates/individual-service.tsx" /* webpackChunkName: "component---src-templates-individual-service-tsx" */),
  "component---src-templates-service-category-tsx": () => import("./../../../src/templates/service-category.tsx" /* webpackChunkName: "component---src-templates-service-category-tsx" */)
}

